import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  Section,
  PropList,
  PropListItem,
} from 'docComponents';
import { Text, Environment, Level } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/level.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          Environment: Environment,
          Level: Level,
          Text: Text,
        }}
        code={snippet}
        platform="react"
        gitHubLink="themes/level"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="level" types={['oneOf', 'string']}>
            <Text>
              Determines which of the four levels to apply as a background
              color.
            </Text>
            <List type="unordered">
              <li>
                <code>0</code> (default)
              </li>
              <li>
                <code>1</code>
              </li>
              <li>
                <code>2</code>
              </li>
              <li>
                <code>3</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>
              Determines which of the two available types of background color is
              applied.
            </Text>
            <List type="unordered">
              <li>
                <code>base</code> (default)
              </li>
              <li>
                <code>accent</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this level in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space?web">
                uniCSS space classes
              </Link>
              .
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            This component must be used in conjunction with the{' '}
            <Link href="/components/themes/environment" isDesignCodeLink>
              Environment
            </Link>{' '}
            component in order to work.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
