import React from 'react';
import { ColorDataTableCode, PlatformTab, Section } from 'docComponents';

const AndroidTab = ({ data }) => {
  return (
    <PlatformTab>
      <Section title="Light Environment">
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="android"
        />
      </Section>

      <Section title="Dark Environment">
        <ColorDataTableCode
          env="dark"
          colorsType="dark"
          data={data}
          platform="android"
        />
      </Section>
    </PlatformTab>
  );
};

export default AndroidTab;
