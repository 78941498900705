import React from 'react';
import {
  ColorDataTableCode,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from 'docComponents';

const AppleTab = ({ data }) => {
  return (
    <PlatformTab>
      <Paragraph>Use the values below.</Paragraph>
      <Section>
        <SectionSubhead>Light Environment</SectionSubhead>
        <ColorDataTableCode
          env="light"
          colorsType="light"
          data={data}
          platform="apple"
        />
        <Section>
          <SectionSubhead>Dark Environment</SectionSubhead>
          <ColorDataTableCode
            env="dark"
            colorsType="dark"
            data={data}
            platform="apple"
          />
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default AppleTab;
